import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  role: null,
  isLoggedIn: false,
  name: "",
  currencyType: "aed",
  monthly_target: {
    revenue: 0,
    earnings: 0,
    customers: 0,
    orders: 0,
  },
  theme_mode: "light",
  settingsBox: false,
  orderStatusCount: {},
  file_url: {},
  archive: false,
  sku: false,
  restrict_single_category_selection: false,
  enable_quality_input: false,
  enable_condition_input: false,
  conditions: [
    "Brand New",
    "Used",
    "Refurbished",
    "High Quality",
    "Replica",
    "Pre Owned",
  ],
  qualities: ["Original", "Non Original", "Tester"],
  custom_order_id: false,
  custom_order_id_pattern: null,
};
const generalStore = createSlice({
  name: "generalStoreAction",
  initialState: initialState,
  reducers: {
    updateAuthData(state, action) {
      if (action.payload.role) state.role = action.payload.role;
      if (action.payload.isLoggedIn)
        state.isLoggedIn = action.payload.isLoggedIn;
      if (action.payload.name) state.name = action.payload.name;
    },
    clearAuthData(state) {
      state.role = null;
      state.isLoggedIn = null;
    },
    updateRole(state, action) {
      state.role = action.payload.role;
    },
    updateFileUrl(state, action) {
      state.file_url = action.payload.file_url;
    },
    updateSettingsBox(state, action) {
      state.settingsBox = action.payload.settingsBox;
    },
    updateThemeMode(state, action) {
      state.theme_mode = action.payload.theme_mode;
    },
    updateCurrencyType(state, action) {
      state.currencyType = action.payload.currencyType;
    },
    updateMonthlyTarget(state, action) {
      if (action.payload.monthly_target)
        state.monthly_target = action.payload.monthly_target;
    },
    updateLoggedIn(state, action) {
      state.isLoggedIn = action.payload.isLoggedIn;
    },
    updateOrderStatusCount(state, action) {
      state.orderStatusCount = action.payload.orderStatusCount;
    },
    updateSKU(state, action) {
      state.sku = action.payload.sku;
    },
    updateArchive(state, action) {
      state.archive = action.payload.archive;
    },
    updateRestrictSingleCategorySelection(state, action) {
      state.restrict_single_category_selection =
        action.payload.restrict_single_category_selection;
    },
    updateEnableConditionInput(state, action) {
      console.log(
        "updating condition input : ",
        action.payload.enable_condition_input
      );
      state.enable_condition_input = action.payload.enable_condition_input;
    },
    updateEnableQualityInput(state, action) {
      console.log(
        "updating quality input : ",
        action.payload.enable_quality_input
      );
      state.enable_quality_input = action.payload.enable_quality_input;
    },
    updateCustomOrderId(state, action) {
      state.custom_order_id = action.payload.custom_order_id;
    },
    updateCustomOrderIdPattern(state, action) {
      state.custom_order_id_pattern = action.payload.custom_order_id_pattern;
    },
  },
});

export const generalStoreActions = generalStore.actions;

export default generalStore;
