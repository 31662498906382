import React, { useState, useRef, useEffect, useMemo } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import style from "./sidebar.module.css";
import { useLocation } from "react-router-dom";
import { useWindowSize } from "../../customHooks/useWindowSize";
import Loader from "../../components/utils/loader";
import api from "../../scripts/api";
import { socket } from "../../scripts/socket";
import { useDispatch, useSelector } from "react-redux";
import { IoHomeOutline } from "react-icons/io5";
import { RxDashboard } from "react-icons/rx";
import { PiStorefront, PiWarehouse } from "react-icons/pi";
import { BsCart3 } from "react-icons/bs";
import { PiKeyReturn } from "react-icons/pi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { PiShootingStarLight } from "react-icons/pi";
import { MdOutlineLocalOffer } from "react-icons/md";
import { LiaClipboardListSolid } from "react-icons/lia";
import { RiEditBoxLine } from "react-icons/ri";
import { IoEyeOutline } from "react-icons/io5";
import { GoArrowSwitch, GoMail } from "react-icons/go";
import { PiUsers } from "react-icons/pi";
import { PiUsersThree } from "react-icons/pi";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { IoNotificationsOutline } from "react-icons/io5";
import { HiOutlineLogout } from "react-icons/hi";
import { IoMdClipboard } from "react-icons/io";
import { VscMenu } from "react-icons/vsc";
import LightDarkToggleSwitch from "../../components/utils/lightDarkToggleSwitch";
import Settings from "../../components/settings/settings";
import { generalStoreActions } from "../../store/general";

const Sidebar = (props) => {
  const location = useLocation();
  const [menuExpand, setMenuExpand] = useState(false);
  const [childExpand, setChildExpand] = useState([]);
  const [hoverSeparator, setHoverSeperator] = useState(false);
  const screenSize = useWindowSize();
  const [countLoader, setCountLoader] = useState(true);
  const orderStatusCount = useSelector(
    (state) => state.generalStore.orderStatusCount
  );
  const notificationCount = useSelector(
    (state) => state.notificationCountStore.count
  );
  const notificationLoader = useSelector(
    (state) => state.notificationCountStore.loader
  );
  const settings = useSelector((state) => state.generalStore.settingsBox);
  const enable_condition_input = useSelector(
    (state) => state.generalStore.enable_condition_input
  );
  const enable_quality_input = useSelector(
    (state) => state.generalStore.enable_quality_input
  );

  console.log({ enable_quality_input, enable_condition_input });
  const dispatch = useDispatch();
  const mainRef = useRef();
  const [navbar, setNavbar] = useState([]);
  const role = useSelector((state) => state.authStore.role);
  const navigate = useNavigate();

  const onCountLoad = async () => {
    setCountLoader(true);
    console.log("role: ", role);
    if (role === "reseller") {
      await api
        .get("/reseller/order/status/count")
        .then((res) => {
          console.log("respo: ", res.data);
          setCountLoader(false);
          dispatch(
            generalStoreActions.updateOrderStatusCount({
              orderStatusCount: res.data.data,
            })
          );
        })
        .catch((err) => {
          setCountLoader(false);
          console.log(err);
        });
    } else if (role === "admin")
      await api
        .get("/order/status/count")
        .then((res) => {
          console.log("respo: ", res.data);
          setCountLoader(false);
          dispatch(
            generalStoreActions.updateOrderStatusCount({
              orderStatusCount: res.data.data,
            })
          );
        })
        .catch((err) => {
          setCountLoader(false);
          console.log(err);
        });
  };

  const onCountLoadWithoutReload = async () => {
    console.log("role: ", role);
    if (role === "reseller") {
      await api
        .get("/reseller/order/status/count")
        .then((res) => {
          console.log("respo: ", res.data);
          dispatch(
            generalStoreActions.updateOrderStatusCount({
              orderStatusCount: res.data.data,
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (role === "admin")
      await api
        .get("/order/status/count")
        .then((res) => {
          console.log("respo: ", res.data);
          dispatch(
            generalStoreActions.updateOrderStatusCount({
              orderStatusCount: res.data.data,
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
  };

  useMemo(() => {
    const menuExpandStored = localStorage.getItem("menu_expand");
    if (menuExpandStored && menuExpandStored === "true") {
      setMenuExpand(true);
    } else {
      setMenuExpand(false);
    }
  }, []);

  useEffect(() => {
    onCountLoad();
    socket.on(
      `${process.env.REACT_APP_PRODUCT_ID}_${props.loaderData.company._id}_reload_orderCount`,
      (message) => {
        onCountLoadWithoutReload();
      }
    );
    return () => {
      socket.off(
        `Disconnected ${process.env.REACT_APP_PRODUCT_ID}_${props.loaderData.company._id}_reload_orderCount`
      );
    };
  }, [role]);
  const [active, setActive] = useState();
  useEffect(() => {
    if (active === true) {
      const element = document.getElementById("active");
      if (element) {
        let dims = element.getBoundingClientRect();
        mainRef.current.scrollTo({
          top: dims.top - 200,
          behavior: "smooth",
        });
      }
    }
  }, [active]);

  const onCheckChildActive = (children) => {
    for (const item of children) {
      if (location.pathname === item.to) return true;
    }
    return false;
  };

  const adminNavbar = [
    { to: "/admin", name: "Dashboard", title: "", icon: IoHomeOutline },
    { to: "/admin/category", name: "Category", icon: RxDashboard },
    {
      name: "Store",
      icon: PiStorefront,
      children: [
        {
          to: "/admin/products",
          name: `Products`,
        },
        ...(enable_condition_input
          ? [{ to: "/admin/conditions", name: "Conditions" }]
          : []),
        ...(enable_quality_input
          ? [{ to: "/admin/quality", name: "Quality" }]
          : []),
        {
          to: "/admin/stocks/manage",
          name: `Inventory`,
        },
        {
          to: "/admin/variants",
          name: `Variants`,
        },
        {
          name: "Purchase",
          to: "/admin/purchase",
        },
        {
          name: "Sales",
          to: "/admin/Sales",
        },
      ],
    },
    {
      name: "Orders",
      icon: BsCart3,
      children: [
        {
          to: "/admin/orders/all",
          name: `All`,
          count: orderStatusCount.all || 0,
        },
        {
          to: "/admin/orders/pending",
          name: `Pending`,
          count: orderStatusCount.order_pending || 0,
        },
        {
          to: "/admin/orders/processing",
          name: `Processing`,
          count: orderStatusCount.order_processing || 0,
        },
        {
          to: "/admin/orders/completed",
          name: `Completed`,
          count: orderStatusCount.order_completed || 0,
        },
      ],
    },
    {
      name: "Returns",
      icon: PiKeyReturn,
      children: [
        {
          to: "/admin/orders/return",
          name: `All`,
          count: orderStatusCount.return_all || 0,
        },
        {
          to: "/admin/orders/return_processing",
          name: `Processing`,
          count: orderStatusCount.return_processing || 0,
        },
        {
          to: "/admin/orders/return_completed",
          name: `Completed`,
          count: orderStatusCount.return_completed || 0,
        },
      ],
    },
    {
      name: "Canceled",
      icon: AiOutlineCloseCircle,
      children: [
        {
          to: "/admin/orders/canceled",
          name: `All`,
          count: orderStatusCount.canceled || 0,
        },
        {
          to: "/admin/orders/not_refunded",
          name: `Not Refunded`,
          count: orderStatusCount.canceled_no_refunded || 0,
        },
        {
          to: "/admin/orders/refunded",
          name: `Refunded`,
          count: orderStatusCount.canceled_refunded || 0,
        },
      ],
    },
    {
      name: "Reviews",
      icon: PiShootingStarLight,
      to: "/admin/reviews",
    },
    {
      name: "Discounts",
      icon: MdOutlineLocalOffer,
      to: "/admin/discounts",
    },
    {
      name: "Blog",
      icon: LiaClipboardListSolid,
      children: [{ to: "/admin/posts", name: "Post" }],
    },
    {
      name: "Site Editor",
      icon: RiEditBoxLine,
      children: [
        { to: "/admin/site/top-header", name: "Top Header" },
        { to: "/admin/site/slider", name: "Sliders" },
        { to: "/admin/site/content", name: "Contents" },
        { to: "/admin/popup/message", name: "Popup Message" },
        { to: "/admin/site/meta", name: "SEO Meta" },
        { to: "/admin/float/icons", name: "Float Icons" },
        { to: "/admin/site/social-media", name: "Social Media" },
      ],
    },

    {
      name: "Recent Viewed",
      icon: IoEyeOutline,
      to: "/admin/product/recent/customer/logs",
    },
    {
      name: "Subscriber",
      icon: GoMail,
      children: [{ to: "/admin/subscriber/email", name: "Email" }],
    },
    {
      name: "Customers",
      icon: PiUsers,
      to: "/admin/customers",
    },
    {
      name: "Referrals",
      icon: GoArrowSwitch,
      to: "/admin/referrals",
    },
    {
      name: "Resellers",
      icon: PiUsersThree,
      to: "/admin/resellers",
    },
    {
      name: "Admins",
      icon: MdOutlineAdminPanelSettings,
      to: "/admin/users",
    },

    {
      name: "Settings",
      icon: IoSettingsOutline,
      children: [
        { to: "/admin/settings/general", name: "General" },
        { to: "/admin/settings/shipping", name: "Shipping" },
        { to: "/admin/settings/pickup", name: "Pickup" },
        { to: "/admin/settings/invoice", name: "Invoice" },
        { to: "/admin/settings/paytabs", name: "Paytabs" },
        { to: "/admin/settings/stripe", name: "Stripe" },
        { to: "/admin/settings/recaptcha", name: "Google Recaptcha" },
        { to: "/admin/settings/smtp", name: "SMTP Server" },
        { to: "/admin/settings/whatsapp", name: "WA API" },
      ],
    },
  ];

  const resellerNavbar = [
    { to: "/reseller", name: "Dashboard", title: "", icon: IoHomeOutline },
    {
      name: "Store",
      icon: PiStorefront,
      children: [
        {
          to: "/reseller/products",
          name: `Products`,
        },
        {
          to: "/reseller/pending/products",
          name: `Pending Products`,
        },
        {
          to: "/reseller/stocks/manage",
          name: `Inventory`,
        },
        {
          to: "/reseller/variants",
          name: `Variants`,
        },
        {
          name: "Purchase",
          to: "/reseller/purchase",
        },
        {
          name: "Sales",
          to: "/reseller/sales",
        },
      ],
    },
    {
      name: "Orders",
      icon: BsCart3,
      children: [
        {
          to: "/reseller/orders/all",
          name: `All`,
          count: orderStatusCount.all || 0,
        },
        {
          to: "/reseller/orders/pending",
          name: `Pending`,
          count: orderStatusCount.order_pending || 0,
        },
        {
          to: "/reseller/orders/processing",
          name: `Processing`,
          count: orderStatusCount.order_processing || 0,
        },
        {
          to: "/reseller/orders/completed",
          name: `Completed`,
          count: orderStatusCount.order_completed || 0,
        },
      ],
    },
    {
      name: "Returns",
      icon: PiKeyReturn,
      children: [
        {
          to: "/reseller/orders/return",
          name: `All`,
          count: orderStatusCount.return_all || 0,
        },
        {
          to: "/reseller/orders/return_processing",
          name: `Processing`,
          count: orderStatusCount.return_processing || 0,
        },
        {
          to: "/reseller/orders/return_completed",
          name: `Completed`,
          count: orderStatusCount.return_completed || 0,
        },
      ],
    },
    {
      name: "Canceled",
      icon: AiOutlineCloseCircle,
      children: [
        {
          to: "/reseller/orders/canceled",
          name: `All`,
          count: orderStatusCount.canceled || 0,
        },
        {
          to: "/reseller/orders/not_refunded",
          name: `Not Refunded`,
          count: orderStatusCount.canceled_no_refunded || 0,
        },
        {
          to: "/reseller/orders/refunded",
          name: `Refunded`,
          count: orderStatusCount.canceled_refunded || 0,
        },
      ],
    },
    {
      name: "Settings",
      icon: IoSettingsOutline,
      children: [
        { to: "/reseller/settings/general", name: "General" },
        { to: "/reseller/settings/shipping", name: "Shipping" },
        { to: "/reseller/settings/pickup", name: "Pickup" },
      ],
    },
  ];

  const [bottom_navbar, setBottomNavbar] = useState([]);

  useEffect(() => {
    navbar.forEach((item, index) => {
      if (item.children && item.children.length > 0) {
        for (const childItem of item.children) {
          if (childItem.to === location.pathname) {
            childExpand[index] = true;
            setActive(true);
          }
        }
      } else {
        setActive(true);
      }
    });
  }, [navbar]);

  useEffect(() => {
    if (role === "reseller") {
      setNavbar(resellerNavbar);
      console.log("notificationCount: ", notificationCount);
      setBottomNavbar([
        {
          name: `Notifications`,
          // count: `${
          //   !notificationLoader ? `( Unread ${notificationCount || 0} )` : ""
          // }`,
          count: `${notificationCount}`,
          loader_id: "notifications",
          icon: IoNotificationsOutline,
          to: "/reseller/notification/logs",
          background: "var(--primary)",
        },

        { to: "/logout", name: "Sign Out", title: "", icon: HiOutlineLogout },
      ]);
    } else if (role === "admin") {
      setNavbar(adminNavbar);
      setBottomNavbar([
        {
          name: `Notifications`,
          count: `${notificationCount}`,
          loader_id: "notifications",
          icon: IoNotificationsOutline,
          to: "/admin/notification/logs",
          background: "var(--primary)",
        },

        { to: "/logout", name: "Sign Out", title: "", icon: HiOutlineLogout },
      ]);
    }
    console.log("orderStatusCount", orderStatusCount);
  }, [role, orderStatusCount, notificationCount]);
  return (
    <React.Fragment>
      {hoverSeparator && <div className="hoverSeperator"></div>}
      <div
        className={`${style.side_bar_main} ${menuExpand ? "" : style.collapse}`}
      >
        <div className={style.side_bar}>
          <div className={style.logo_section}>
            EC
            <span>&nbsp;Manager</span>
          </div>
          <div className={style.menu_bar} data-tg-title="Sidebar">
            <div className={style.menu_title_section}>
              {/* <p className={style.menu_title}>
                <VscMenu /> <div className={style.menu_text}>Navigation</div>
              </p> */}
              <div
                className={`${style.menu_accordion}`}
                onClick={(e) => {
                  e.target.blur();
                  setMenuExpand((prev) => {
                    localStorage.setItem("menu_expand", !prev);
                    return !prev;
                  });
                  setHoverSeperator(true);
                  setTimeout(() => setHoverSeperator(false), 100);
                }}
              ></div>
            </div>
            <div className={style.menu_bar_content_section}>
              <div className={style.menu_bar_content} ref={mainRef}>
                <ul className={style.navBar}>
                  {navbar.map((item, index) => (
                    <div
                      className={style.link_menu}
                      key={`${item.name}_${index}`}
                      onClick={() => {
                        console.log("item.to", item.to);
                        if (item.to) {
                          navigate(item.to);
                        }
                      }}
                    >
                      <li
                        key={index}
                        className={`${
                          item.children && childExpand[index]
                            ? style.has_children
                            : style.menu_btn
                        } ${
                          item.to === location.pathname
                            ? style.active
                            : item.children && onCheckChildActive(item.children)
                            ? style.child_active
                            : ""
                        }`}
                      >
                        {item.hasOwnProperty("to") && !item.children ? (
                          <span className={style.click_padd}>
                            <div
                              className={`${
                                item.count
                                  ? style.click_padd_count
                                  : style.flex_center
                              }`}
                            >
                              {item.icon && <item.icon />}

                              <div className={style.menu_text}>{item.name}</div>
                            </div>
                          </span>
                        ) : (
                          <div
                            className={`${style.extend_product} ${
                              style.click_padd
                            } ${childExpand[index] ? style.expand_padd : ""}`}
                            onClick={() => {
                              setChildExpand((prev) => {
                                let newArray = [...prev];
                                newArray[index] = !newArray[index];
                                return newArray;
                              });
                            }}
                          >
                            <p className={`${style.menu_name}`}>
                              <span>
                                {item.icon && <item.icon />}
                                <div className={style.menu_text}>
                                  {item.name}
                                </div>
                              </span>
                              <button
                                className={`button ${
                                  childExpand[index] === true ? style.hide : ""
                                }`}
                              ></button>
                            </p>
                          </div>
                        )}
                        {childExpand[index] && item.children && (
                          <ul className={style.child_list}>
                            {item.children.map((childItem, index) => (
                              <NavLink
                                to={childItem.to}
                                className={({ isActive }) =>
                                  isActive ? style.active : undefined
                                }
                                key={`${index}_${childItem.name}`}
                              >
                                <li
                                  className={`${
                                    !countLoader && childItem.count != undefined
                                      ? style.count
                                      : ""
                                  } ${
                                    childItem.children ? "" : style.menu_btn
                                  } ${
                                    childItem.to === location.pathname
                                      ? style.active
                                      : ""
                                  } ${style.click_padd} `}
                                  id={`${
                                    childItem.to === location.pathname
                                      ? "active"
                                      : ""
                                  }`}
                                  data-count={childItem.count}
                                >
                                  <div className={style.menu_text}>
                                    {childItem.name}
                                  </div>
                                  {["orders", "returns"].includes(
                                    item.name.toLowerCase()
                                  ) &&
                                    countLoader && (
                                      <Loader className={style.loader_count} />
                                    )}
                                </li>
                              </NavLink>
                            ))}
                          </ul>
                        )}
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
              <div className={`${style.bottom_menu} ${style.menu_bar_content}`}>
                <ul className={style.navBar}>
                  {bottom_navbar.map((childItem, index) => (
                    <>
                      <NavLink
                        to={childItem.to}
                        className={style.link_menu}
                        key={`${childItem.name}_${index}`}
                      >
                        <li
                          key={childItem.to}
                          className={`${
                            childItem.count !== undefined && !notificationLoader
                              ? style.count
                              : ""
                          } ${childItem.children ? "" : style.menu_btn} ${
                            childItem.to === location.pathname
                              ? style.active
                              : ""
                          }`}
                          data-count={
                            !isNaN(childItem.count) ? childItem.count : 0
                          }
                        >
                          {childItem.hasOwnProperty("to") &&
                            !childItem.children && (
                              <React.Fragment>
                                {childItem.icon && <childItem.icon />}

                                <div className={style.menu_text}>
                                  {childItem.name}
                                </div>
                                {/* {childItem.count &&
                              ["notifications"].includes(
                                childItem.loader_id &&
                                  childItem.loader_id.toLowerCase()
                              ) && (
                                <>
                                  <div className={style.count_notification}>
                                    {childItem.count}
                                  </div>
                                </>
                              )}
                            {["notifications"].includes(
                              childItem.loader_id &&
                                childItem.loader_id.toLowerCase()
                            ) && */}
                                {childItem.count !== undefined &&
                                  notificationLoader && (
                                    <Loader className={style.loader_count} />
                                  )}
                              </React.Fragment>
                            )}
                        </li>
                      </NavLink>
                    </>
                  ))}
                  {/* <p className={style.version_section}>Version 12023.1</p> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Settings
        onOpen={settings}
        onClose={() =>
          dispatch(
            generalStoreActions.updateSettingsBox({ settingsBox: false })
          )
        }
      />
    </React.Fragment>
  );
};

export default Sidebar;
