import { configureStore } from "@reduxjs/toolkit";
import authStore from "./auth";
import api from "../scripts/api";
import generalStore, { generalStoreActions } from "./general";
import notificationCountStore, {
  notificationCountStoreActions,
} from "./notificationCount";

const store = configureStore({
  reducer: {
    authStore: authStore.reducer,
    notificationCountStore: notificationCountStore.reducer,
    generalStore: generalStore.reducer,
  },
});

export default store;

// Admins Notification Section

export const loadNotificationCount = async () => {
  store.dispatch(notificationCountStoreActions.setLoader({ loader: true }));
  await api
    .get("/notification/unread/count")
    .then((res) => {
      store.dispatch(
        notificationCountStoreActions.updateCount({ count: res.data.count })
      );
      store.dispatch(
        notificationCountStoreActions.setLoader({ loader: false })
      );
    })
    .catch((err) => {
      store.dispatch(
        notificationCountStoreActions.setLoader({ loader: false })
      );
      console.log("err:", err);
    });
};

export const loadNotificationCountWithoutReload = async () => {
  await api
    .get("/notification/unread/count")
    .then((res) => {
      store.dispatch(
        notificationCountStoreActions.updateCount({ count: res.data.count })
      );
    })
    .catch((err) => {
      console.log("err:", err);
    });
};

// Reseller Notification Section

export const loadResellerNotificationCount = async () => {
  store.dispatch(notificationCountStoreActions.setLoader({ loader: true }));
  await api
    .get("/reseller/notification/unread/count")
    .then((res) => {
      store.dispatch(
        notificationCountStoreActions.updateCount({ count: res.data.count })
      );
      store.dispatch(
        notificationCountStoreActions.setLoader({ loader: false })
      );
    })
    .catch((err) => {
      store.dispatch(
        notificationCountStoreActions.setLoader({ loader: false })
      );
      console.log("err:", err);
    });
};

export const loadResellerNotificationCountWithoutReload = async () => {
  await api
    .get("/reseller/notification/unread/count")
    .then((res) => {
      store.dispatch(
        notificationCountStoreActions.updateCount({ count: res.data.count })
      );
    })
    .catch((err) => {
      console.log("err:", err);
    });
};

//

export const loadData = async (type = "") => {
  if (type === "reseller") {
    await Promise.all([
      await api
        .get(
          "/reseller/settings/general?field=currency_type monthly_target archive sku restrict_single_category_selection enable_quality_input enable_condition_input custom_order_id custom_order_id_pattern"
        )
        .then(async (res) => {
          console.log("indexStore :: data :: ", res.data);
          if (res.data.success === true) {
            console.log(
              "res.data.data",
              res.data.data,
              res.data.data.enable_condition_input,
              res.data.data.enable_quality_input
            );
            if (res.data && res.data.data && res.data.data.currency_type)
              store.dispatch(
                generalStoreActions.updateCurrencyType({
                  currencyType: res.data.data.currency_type,
                })
              );

            store.dispatch(
              generalStoreActions.updateMonthlyTarget({
                monthly_target: res.data.data.monthly_target,
              })
            );
            store.dispatch(
              generalStoreActions.updateRestrictSingleCategorySelection({
                restrict_single_category_selection:
                  res.data.data.restrict_single_category_selection,
              })
            );
          }
          if (res.data && res.data.data && res.data.data.archive) {
            store.dispatch(
              generalStoreActions.updateArchive({
                archive: res.data.data.archive,
              })
            );
          }
          if (res.data && res.data.data && res.data.data.sku) {
            store.dispatch(
              generalStoreActions.updateSKU({
                sku: res.data.data.sku,
              })
            );
          }
          if (
            res.data &&
            res.data.data &&
            res.data.data.enable_condition_input
          ) {
            console.log({
              conditionInput: res.data.data.enable_condition_input,
            });
            store.dispatch(
              generalStoreActions.updateEnableConditionInput({
                enable_condition_input: res.data.data.enable_condition_input,
              })
            );
          }
          if (res.data && res.data.data && res.data.data.enable_quality_input) {
            store.dispatch(
              generalStoreActions.updateEnableQualityInput({
                enable_quality_input: res.data.data.enable_quality_input,
              })
            );
          }
        })
        .catch((err) => {
          console.log("err:", err);
        }),
      await api
        .get("/reseller/settings/general/main?field=file_url")
        .then(async (res) => {
          if (res.data.success === true) {
            console.log("res.data.data", res.data.data);
            store.dispatch(
              generalStoreActions.updateFileUrl({
                file_url: res.data.data.file_url,
              })
            );
          }
        })
        .catch((err) => {
          console.log("err:", err);
        }),
    ]);
  } else {
    await api
      .get(
        "/settings/general?field=currency_type monthly_target file_url archive sku restrict_single_category_selection enable_quality_input enable_condition_input custom_order_id custom_order_id_pattern"
      )
      .then(async (res) => {
        console.log("res.data.data index", res.data);
        if (res.data.success === true) {
          if (res.data && res.data.data && res.data.data.currency_type)
            store.dispatch(
              generalStoreActions.updateCurrencyType({
                currencyType: res.data.data.currency_type,
              })
            );
          store.dispatch(
            generalStoreActions.updateFileUrl({
              file_url: res.data.data.file_url,
            })
          );
          store.dispatch(
            generalStoreActions.updateMonthlyTarget({
              monthly_target: res.data.data.monthly_target,
            })
          );
          if (res.data && res.data.data && res.data.data.archive) {
            store.dispatch(
              generalStoreActions.updateArchive({
                archive: res.data.data.archive,
              })
            );
          }
          if (res.data && res.data.data && res.data.data.sku) {
            store.dispatch(
              generalStoreActions.updateSKU({
                sku: res.data.data.sku,
              })
            );
          }
          if (
            res.data &&
            res.data.data &&
            res.data.data.restrict_single_category_selection
          ) {
            store.dispatch(
              generalStoreActions.updateRestrictSingleCategorySelection({
                restrict_single_category_selection:
                  res.data.data.restrict_single_category_selection,
              })
            );
          }
          if (
            res.data &&
            res.data.data &&
            res.data.data.enable_condition_input
          ) {
            store.dispatch(
              generalStoreActions.updateEnableConditionInput({
                enable_condition_input: res.data.data.enable_condition_input,
              })
            );
          }
          if (res.data && res.data.data && res.data.data.enable_quality_input) {
            store.dispatch(
              generalStoreActions.updateEnableQualityInput({
                enable_quality_input: res.data.data.enable_quality_input,
              })
            );
          }
          if (res.data && res.data.data && res.data.data.custom_order_id) {
            store.dispatch(
              generalStoreActions.updateCustomOrderId({
                custom_order_id: res.data.data.custom_order_id,
              })
            );
          }
          if (
            res.data &&
            res.data.data &&
            res.data.data.custom_order_id_pattern
          ) {
            store.dispatch(
              generalStoreActions.updateCustomOrderIdPattern({
                custom_order_id_pattern: res.data.data.custom_order_id_pattern,
              })
            );
          }
        }
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }
};

// Load Count without reload

export const onOrderCountLoadWithoutReload = async () => {
  const role = store.getState().authStore.role;
  if (role === "reseller") {
    await api
      .get("/reseller/order/status/count")
      .then((res) => {
        console.log("respo: ", res.data);
        store.dispatch(
          generalStoreActions.updateOrderStatusCount({
            orderStatusCount: res.data.data,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  } else if (role === "admin")
    await api
      .get("/order/status/count")
      .then((res) => {
        console.log("respo: ", res.data);
        store.dispatch(
          generalStoreActions.updateOrderStatusCount({
            orderStatusCount: res.data.data,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
};
